<template>
  <div>
    <v-card>
      <v-card-title>CONSOLIDADO SOLICITUDES PARA COMPRA</v-card-title>
      <v-card-text>
        <div class="d-flex justify-end py-1">
          <v-btn color="blue" dark @click="generarSolicitud()">
            Nueva solicitud
          </v-btn>
        </div>
        
        <v-alert
          border="left"
          outlined
          text
          :type="alert.tipo"
          v-if="alert.mostrar"
        >
          <v-row>
            <v-col>
              <h4>{{ alert.mensaje }}</h4>
            </v-col>
            <v-col class="text-right">
              <v-btn
                :color="alert.color_boton"
                elevation="2"
                small
                dark
                @click="cerrar_alert"
                >Cerrar</v-btn
              >
            </v-col>
          </v-row>
        </v-alert>
      </v-card-text>
    </v-card>
    <div class="card">
      <div class="card-header card-header-primary card-header-icon">
        <div :class="`card-icon ${color_icono}`">
          <i class="material-icons" v-show="icono">{{ icono }}</i>
        </div>
        <h4 class="card-title">
          <div class="row">
            <div class="col-md-6">
              {{ titulo }}
            </div>
          </div>
        </h4>
      </div>
      <div class="card-body">
        <spinner v-if="spinner"></spinner>
        <v-simple-table :height="table_height">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">NRO</th>
                <th class="text-center">FECHA EMISIÓN</th>
                <th class="text-center">EMISOR</th>
                <th class="text-center">ACCIONES</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in solicitudes"
                :key="index"
                class="text-center"
              >
                <td>{{ item.nro }}</td>
                <td class="text-no-wrap">{{ item.fecha | format_date_spanish }}</td>
                <td>{{ item.nombre_usuario_emisor }}</td>
                <td>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <a
                        class="ma-2"
                        @click="generarPDF(item.id)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon large color="red">
                          mdi-file-pdf-box
                        </v-icon>
                      </a>
                    </template>
                    <span>Generar PDF</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>

    <v-alert v-if="empty_data" border="right" color="blue-grey" dark>
      No hay solicitudes de compra...
    </v-alert>

  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
export default {
  data: () => ({
    table_height: 550,
    spinner: false,
    titulo: "SOLICITUDES PARA COMPRA DE MATERIALES",
    color_icono: "azul",
    icono: "list",
    solicitudes: [],
    empty_data: false,
    alert: {
      mostrar: false,
      tipo: "",
      color_boton: "",
      mensaje: "",
    },
  }),
  mounted() {
    this.loadSolicitudesCompra();
  },
  computed: {
    ...mapState([
      "loading",
      "base_url",
      "headers",
      "headers_file",
      "wellboat",
      "user",
      "storage_url",
    ]),
  },
  methods: {
    cerrar_alert() {
      this.alert.mostrar = false;
      this.alert.tipo = "";
      this.alert.color_boton = "";
      this.alert.mensaje = "";
    },
    async loadSolicitudesCompra() {
      this.spinner = true;
      this.mostrarLoading("Cargando listado de solicitudes...");
      const url = `${this.base_url}solicitudes_compras`;
      await this.axios
        .get(url, this.headers)
        .then((response) => {
          //this.solicitudes = response.data.data.filter(d=>this.user.areas_ids.includes(d.areas_id));
          this.solicitudes = response.data;
          response.data.data.length === 0 && (this.empty_data = true);
          this.ocultarLoading();
        })
        .catch((error) => {
          console.log(error);
        });
      this.spinner = false;
    },
    volver() {
      this.$router.push("solicitud_materiales");
    },
    async generarPDF(id) {
      this.spinner = true;
      const url = `${this.base_url}solicitudes_compras/pdf/${id}`;
      await this.axios
        .post(url, {}, this.headers)
        .then((response) => {
          if (response.status === 200) {
            window.open(`${this.storage_url}materiales/compras/${response.data.url_pdf}`);
          }
        }).catch(() => {
          this.alert.mostrar = true;
          this.alert.tipo = "error";
          this.alert.color_boton = "red";
          this.alert.mensaje = "Hubo un error al generar PDF";
        });
      this.spinner = false;
    },
    generarSolicitud() {
      this.$router.push('solicitud_compras_generar');
    },
    ...mapMutations(["mostrarLoading", "ocultarLoading"]),
  },
};
</script>
<style scoped>
th {
  background-color: #355f94 !important;
  color: #fff !important;
  border: 1px solid #fff;
}
</style>
